<template>
  <div>
      <Card>
      <template slot="outer">
        <div class="mb-4 flex justify-between align-center">
          <div class="py-3 inline-flex align-center">
            <svg class="w-5" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" fill="#5B5B5B">
              <path d="M18 2L6 2C4.898438 2 4 2.898438 4 4L4 15C4 15.628906 4.300781 16.21875 4.800781 16.601563L12 22L19.199219 16.601563C19.699219 16.21875 20 15.628906 20 15L20 4C20 2.898438 19.101563 2 18 2 Z M 13 15L11 15L11 13L13 13 Z M 13 12L11 12C11 9.449219 12.992188 9.902344 12.992188 7.804688C12.992188 7.390625 12.816406 6.476563 11.941406 6.476563C11.679688 6.476563 10.957031 7 10.957031 8L9.015625 8C9.015625 8 8.585938 5.007813 11.945313 5.007813C14.527344 5.007813 15 6.933594 15 7.792969C15 10.144531 13 10.296875 13 12Z" fill="#5B5B5B" />
            </svg>
            <h3 class="pl-2 text-uppercase text-lg">FAQS</h3>
          </div>
        </div>
      </template>
      <CardBody class="p-3 relative">
        <div class="mb-4 flex justify-end flex-wrap justify-xs-center">
          <SearchBox
            placeholder="Search"
            class="w-74 p-2 m-1"
            :results="searchResults"
            v-model="keyword"
            result-key="title"
            @on-result="onResult"
            />
        </div>
        <md-divider></md-divider>
        <Table :items="assignToTrainerOrAdmin" hover-action>
          <template slot="row" slot-scope="{ item }">
            <md-table-cell md-sort-by="id" md-label="SL" md-numeric>{{ item.id }}</md-table-cell>
            <md-table-cell md-sort-by="qId" md-label="Q_ID">{{ item.qId }}</md-table-cell>
            <md-table-cell md-sort-by="title" md-label="TITLE">
              {{ item.title }}
              <span v-if="item.bulk" class="ml-2 rounded px-1 bg-tahiti-gold text-white">bulk</span>
            </md-table-cell>
            <md-table-cell md-sort-by="section" md-label="SECTION">{{ item.section }}</md-table-cell>
            <md-table-cell md-sort-by="item" md-label="ITEM">{{ item.item }}</md-table-cell>
            <md-table-cell md-sort-by="branch" md-label="BRANCH">{{ item.branch }}</md-table-cell>
            <md-table-cell md-sort-by="assignAt" md-label="ASSIGN AT">{{ item.assignAt }}</md-table-cell>
            <md-table-cell md-sort-by="assignAt" md-label="LAST MODIFIED AT">{{ item.assignAt }}</md-table-cell>
            <span class="action">
              <md-icon @click.native="onShowDetails" class="bg-victoria rounded-full p-1 text-white text-base mr-1">visibility</md-icon>
              <md-icon class="bg-default rounded-full p-1 text-gray-700 text-base">delete</md-icon>
            </span>
          </template>
        </Table>

        <div @click="onShowCreate" style="position: absolute; bottom: 0; right: 0"
          class="bg-victoria pointer center w-24 h-24 rounded-full">
          <md-icon class="text-6xl text-white">add</md-icon>
        </div>
      </CardBody>
    </Card>
    <div class="flex justify-end align-center mt-8">
          <Paginate
            :start="paginate.start"
            :end="paginate.end"
            :total="paginate.total"
            :limit="paginate.limit"
            @on-start="onStart"
            @on-end="onEnd"
          />
      </div>
    <Dialog>
      <component :is="component"></component>
    </Dialog>
  </div>
</template>

<script>
import {
    Card,
    Table,
    Dialog,
    CardBody,
    Paginate,
    SearchBox
    } from "@/components";
import { PromptDetails, PromptCreate, PromptEdit } from "@/components/molecule";

import cms from "@/data/cms";
import { assignToTrainerOrAdmin } from '@/data/master/qatm/question-management';
import { mapMutations } from "vuex";
import { paginate } from "@/mixins/paginate";
export default {
    mixins: [paginate],
    components: {
        Card,
        Table,
        Dialog,
        Paginate,
        CardBody,
        SearchBox,
        PromptEdit,
        PromptDetails,
        PromptCreate
  },
  data() {
      return {
          currentIndex: 0,
          component: 'PromptCreate',
          types: cms.types,
          keyword: '',
          selectedKeyword: '',
          query: '?',
          visibility: true,
          pagination: true,
          selectedValue: null,
          itemIndex: null,
          status: '',
          searchParams : new URLSearchParams(),
          paginate: {
            start: 1,
            end: 20,
            total: 100,
            limit: 20
          }
      }
  },
  computed: {
    assignToTrainerOrAdmin() {
      if(this.pagination) {
        return assignToTrainerOrAdmin.slice(this.paginate.start, this.paginate.end);
      }
      return assignToTrainerOrAdmin;
    },
    searchResults() {
        if(this.keyword !== '' && this.keyword !== this.selectedKeyword) {
          return assignToTrainerOrAdmin.filter(item => this.toLower(item.title).includes(this.toLower(this.keyword)))
        }
        return [];
      }
  },
   methods: {
    ...mapMutations({
      dialog: "setShowDialog",
    }),
    onShowDetails() {
      
    },
    onShowCreate() {
     
    },
    onShowEdit() {
     
    },
    onResult(value) {
      this.keyword = this.selectedKeyword = value;
      this.onPrepareQueryString('search', this.keyword);
    },
    onStart(value) {
      this.paginate.start -= value;
      this.paginate.end -= value;
      this.onPrepareQueryString('offset', this.paginate.start);
      this.onPrepareQueryString('limit', this.paginate.end);
    },
    onEnd(value) {
      this.paginate.start += value;
      this.paginate.end += value;
      this.onPrepareQueryString('offset', this.paginate.start);
      this.onPrepareQueryString('limit', this.paginate.end);
    },
    onPrepareQueryString(key, value) {
      if(this.searchParams.has(key)) {
          this.searchParams.delete(key);
      }
      this.searchParams.append(key, value);
      console.log(this.searchParams.toString());
    },
    toLower(text){
      return text.toString().toLowerCase()
    },
  },
}
</script>